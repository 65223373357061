import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index.vue";
import i18n from "../lang/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    redirect: "/trade",
    component: Layout,
    children: [
      {
        path: "trade",
        component: () => import("@/pages/Trade.vue"),
        name: "Trade",
        meta: {
          title: i18n.tc("trade"),
          permission: "Record"
        }
      },
      {
        path: "order",
        component: () => import("@/pages/Order.vue"),
        name: "Order",
        meta: {
          title: i18n.tc("orderManage"),
          permission: "Record"
        }
      },
      {
        path: "tradePoint",
        component: () => import("@/pages/TradePoint.vue"),
        name: "TradePoint",
        meta: {
          title: i18n.tc("tradePoint"),
          permission: "Record"
        }
      },
      {
        path: "dataCompass",
        component: () => import("@/pages/DataCompass/index.vue"),
        name: "DataCompass",
        meta: {
          title: i18n.tc("dataScreenRouter"),
          permission: "DataCompass"
        }
      },
      {
        path: "payment",
        component: () => import("@/pages/Payment/index.vue"),
        name: "Payment",
        meta: {
          title: i18n.tc("receipt"),
          permission: "Settle"
        }
      },

      {
        path: "statistics",
        component: () => import("@/pages/Statistics/index.vue"),
        name: "Statistics",
        meta: {
          title: i18n.tc("statistic"),
          permission: "Stat"
        }
      },

      {
        path: "message",
        component: () => import("@/pages/Message/index.vue"),
        name: "Message",
        meta: {
          title: i18n.tc("message.notificationList")
        },

        children: [
          {
            path: "",
            component: () => import("@/pages/Message/Message.vue"),
            name: "Message",
            meta: {
              title: i18n.tc("message.notificationList")
            }
          },
          {
            path: "management",
            component: () => import("@/pages/Message/Management.vue"),
            name: "MessageManagement",
            meta: {
              title: i18n.tc("message.notificationManagement"),
              permission: "Message"
            }
          },
          {
            path: "sysManagement",
            component: () => import("@/pages/Message/SysManagement.vue"),
            name: "SysManagement",
            meta: {
              title: i18n.tc("message.notificationManagement"),
              permission: "Message"
            }
          }
        ]
      },

      {
        path: "deposit",
        component: () => import("@/pages/Payment/withpointindex.vue"),
        name: "deposit",
        meta: {
          title: i18n.tc("receipt"),
          permission: "Settle"
        }
      },

      {
        path: "tradeType",
        component: () => import("@/pages/TradeType/index.vue"),
        name: "TradeType",
        redirect: "tradetype/tradeType",
        meta: {
          title: i18n.tc("tradeType")
          // permission: "Record"
        },
        children: [
          {
            path: "tradeType",
            component: () => import("@/pages/TradeType/TradeType.vue"),
            name: "TradeType",
            meta: { title: i18n.tc("tradeType") }
          },
          {
            path: "paymentClass",
            component: () => import("@/pages/TradeType/PaymentClass.vue"),
            name: "PaymentClass",
            meta: { title: i18n.tc("paymentClass") }
          },
          {
            path: "paymentType",
            component: () => import("@/pages/TradeType/PaymentType.vue"),
            name: "PaymentType",
            meta: {
              title: i18n.tc("paymentType")
            }
          },
          {
            path: "tableTent",
            component: () => import("@/pages/TradeType/TableTent.vue"),
            name: "TableTent",
            meta: {
              title: i18n.tc("tableTent")
            }
          },
          {
            path: "tenant",
            component: () => import("@/pages/TradeType/Tenant.vue"),
            name: "Tenant",
            meta: {
              title: i18n.tc("tenant")
            }
          },
          {
            path: "paymentLogos",
            component: () => import("@/pages/TradeType/PaymentLogos.vue"),
            name: "PaymentLogos",
            meta: {
              title: i18n.tc("paymentLogos.title")
            }
          }
        ]
      },
      {
        path: "tenant",
        component: () => import("@/pages/Tenant/index.vue"),
        name: "Tenant",
        redirect: "tenant/tenant",
        meta: {
          title: i18n.tc("tenant")
          // permission: "Record"
        },
        children: [
          {
            path: "tenant",
            component: () => import("@/pages/Tenant/Tenant.vue"),
            name: "TenantManage",
            meta: { title: i18n.tc("tenantManage") }
          },
          {
            path: "tenantType",
            component: () => import("@/pages/Tenant/Type.vue"),
            name: "TenantType",
            meta: { title: i18n.tc("tenantType") }
          },
          {
            path: "tenantConfig",
            component: () => import("@/pages/Tenant/Config.vue"),
            name: "TenantConfig",
            meta: { title: i18n.tc("tenantConfig") }
          },
          {
            path: "CreditCardConfig",
            component: () => import("@/pages/Tenant/CreditCard.vue"),
            name: "CreditCardConfig",
            meta: { title: i18n.tc("CreditCardConfig") }
          }
        ]
      },
      {
        path: "feedback",
        component: () => import("@/pages/Feedback/Feedback.vue"),
        name: "Feedback",
        // redirect: "feedback/feedback",
        meta: {
          title: i18n.tc("feedbackManage")
          // permission: "Record"
        }
      },
      {
        path: "feedback2",
        component: () => import("@/pages/Feedback/Feedback2.vue"),
        name: "Feedback2",
        // redirect: "feedback/feedback",
        meta: {
          title: i18n.tc("feedbackManage2")
          // permission: "Record"
        }
      },
      {
        path: "paymentManage",
        component: () => import("@/pages/PaymentManage.vue"),
        name: "PaymentManage",
        meta: {
          title: i18n.tc("paymentManage"),
          permission: "Record"
        }
      },
      // {
      //   path: "tradeDaily",
      //   component: () => import("@/pages/DataCompass/tradeDaily.vue"),
      //   name: "TradeDaily",
      //   meta: {
      //     title: i18n.tc("tradeDailyRouter"),
      //     permission: "DataCompass"
      //   }
      // },
      // {
      //   path: "tradeMonthly",
      //   component: () => import("@/pages/DataCompass/tradeMonthly.vue"),
      //   name: "TradeMonthly",
      //   meta: {
      //     title: i18n.tc("tradeMonthlyRouter"),
      //     permission: "DataCompass"
      //   }
      // },
      {
        path: "setting",
        component: () => import("@/pages/Message/index.vue"),
        name: "Setting",
        children: [
          // {
          //   path: "payment",
          //   component: () => import("@/pages/Setting/Payment.vue"),
          //   name: "SetPayment",
          //   meta: { title: i18n.tc("paymentManagement") }
          // },
          // {
          //   path: "shop",
          //   component: () =>
          //     process.env.VUE_APP_NEWSTOREVERSION === "true"
          //       ? import("@/pages/Setting/Store.vue")
          //       : import("@/pages/Setting/Shop.vue"),
          //   name: "Shop",
          //   meta: {
          //     title: i18n.tc("storeManagement"),
          //     permission: "Shop"
          //   }
          // {
          //   path: "payment",
          //   component: () => import("@/pages/Setting/Payment.vue"),
          //   name: "SetPayment",
          //   meta: { title: i18n.tc("paymentManagement") }
          // },
          // {
          //   path: "shop",
          //   component: () => import("@/pages/Message/index.vue"),
          //   name: "Shop"
          // },
          {
            path: "tableTent",
            component: () => import("@/pages/TableTent/index.vue"),
            name: "TableTent",
            meta: { title: i18n.tc("tableTent") }
          },

          {
            path: "points",
            component: () => import("@/pages/Setting/PointsRules.vue"),
            name: "PointsRules",
            meta: {
              title: i18n.tc("pointsRules")
            }
          },

          {
            path: "user",
            component: () => import("@/pages/Setting/User.vue"),
            name: "User",
            meta: {
              title: i18n.tc("userPageTitle"),
              permission: "User"
            }
          },

          {
            path: "rights",
            component: () => import("@/pages/Setting/Rights.vue"),
            name: "Rights",
            meta: {
              title: i18n.tc("userRight"),
              permission: "Permission"
            }
          },
          {
            path: "qrmanage",
            component: () => import("@/pages/Setting/QRManage.vue"),
            name: "QRManage",
            meta: { title: i18n.tc("QRManage") }
          },
          {
            path: "adminUser",
            component: () => import("@/pages/Setting/AdminUser.vue"),
            name: "adminUser",
            meta: {
              title: i18n.tc("merchantManagement")
              // permission: "Ent"
            }
          },
          {
            path: "adminUserRole",
            component: () => import("@/pages/Setting/AdminUserRole.vue"),
            name: "adminUserRole",
            meta: {
              title: i18n.tc("userRight")
            }
          },
          {
            path: "adminUserManage",
            component: () => import("@/pages/Setting/AdminUserManage.vue"),
            name: "adminUserManage",
            meta: {
              title: i18n.tc("userPageTitle")
            }
          },
          {
            path: "MFAManage",
            component: () => import("@/pages/Setting/MFA.vue"),
            name: "MFAManage",
            meta: {
              title: i18n.tc("MFAManagement"),
              permission: "EntMfaConfig"
            }
          }
        ]
      },
      {
        path: "systrade",
        component: () => import("@/pages/Admin/AdminTrade.vue"),
        name: "systrade",
        meta: {
          title: i18n.tc("trade"),
          permission: "Refund"
        }
      },

      {
        path: "help",
        // component: () => import("@/pages/Help/Manual.vue"),
        redirect: "help/manual",
        name: "Help",
        children: []
      },
      {
        path: "help/manual",
        component: () => import("@/pages/Help/Manual.vue"),
        name: "Manual",
        meta: {
          title: i18n.tc("userGuid")
        }
      },
      {
        path: "help/support",
        component: () => import("@/pages/Help/Support.vue"),
        name: "Support",
        meta: {
          title: i18n.tc("customerSupport")
        }
      },

      //包括point集计
      {
        path: "stat",
        component: () => import("@/pages/Statistics/withpointindex.vue"),
        name: "stat",
        meta: {
          title: i18n.tc("statistic"),
          permission: "Stat"
        }
      },
      // {
      //   path: "subAdmin",
      //   component: () => import("@/pages/subAdmin/index.vue"),
      //   name: "subAdmin",
      //   redirect: "subAdmin/role",
      //   children: [
      //     {
      //       path: "role",
      //       component: () => import("@/pages/subAdmin/role.vue"),
      //       name: "role",
      //       meta: {
      //         title: i18n.tc("subAdminRole")
      //       }
      //     },
      //     {
      //       path: "user",
      //       component: () => import("@/pages/subAdmin/user.vue"),
      //       name: "user",
      //       meta: {
      //         title: i18n.tc("subAdminUser")
      //       }
      //     }
      //   ],
      //   meta: {
      //     title: i18n.tc("subAdmin")
      //     // permission: "Stat"
      //   }
      // },
      {
        path: "notFound",
        component: () => import("@/pages/Error/NotFound.vue"),
        name: "NotFound"
      }
    ]
  },
  //定义路由需要在上面定义
  {
    path: "*",
    redirect: "/notFound"
  }
];

const router = new VueRouter({
  // base: process.env.BASE_URL,
  routes
});

export default router;
