import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import { Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./lang";
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

import moment from "moment";
Vue.prototype.moment = moment;

import VueQrcode from "@chenfengyuan/vue-qrcode";
Vue.component(VueQrcode.name, VueQrcode);

//该注释代码不要删除
// import messaging from "./firebasemessage/index";
// Vue.prototype.$messaging = messaging;

import "./styles/index.scss";
import "@/global_components";
import "@/icons";
//权限检查
import "./router/permission";
import "./directives/permission";
import { getUserInfo } from "api/user";
import VueGtag from "vue-gtag";
import Keycloak from "keycloak-js";

let baseUrl = "/apiproxy";
let initGuards;

Vue.prototype.$baseUrl = baseUrl;
Vue.prototype.process = { env: process.env };
Vue.config.productionTip = false;
Vue.config.devtools = true;

// function getOEMConfig() {
//   let xhr = new XMLHttpRequest();
//   xhr.open("GET", baseUrl + "/api/tenant/getcurrenttenantwebconfig", false);

//   xhr.onerror = function(e) {
//     console.error(xhr.statusText);
//   };

//   xhr.setRequestHeader("api-version", "2.0");

//   xhr.onload = function(e) {
//     if (xhr.readyState === 4) {
//       if (xhr.status === 200) {
//         console.log("OEM Settings:", JSON.parse(xhr.responseText));
//         if (JSON.parse(xhr.responseText).statusCode == 200 && JSON.parse(xhr.responseText).resultStatus == "Success") {
//           console.log("getOEMConfigSuccess");

//           Vue.prototype.$settings = JSON.parse(xhr.responseText).result;

//           //增加判断逻辑，如果系统维护，就跳转到维护界面
//           if (!window.location.search.includes("systemconfig")) {
//             if (Vue.prototype.$settings.showServiceUnavailable) {
//               window.location.replace("/m.html");
//             }
//           }

//           //如果envmode = development 给我注释掉loading
//           console.log("ENV:", process.env.NODE_ENV);
//           let loading;
//           if (!(process.env.NODE_ENV === "development")) {
//             loading = Loading.service({
//               lock: true,
//               text: "Loading...",
//               spinner: "el-icon-loading",
//               fullscreen: true
//             });
//           }

//           //动态初始化keycloak
//           let initOptions = {
//             url: Vue.prototype.$settings.keycloakUrl,
//             realm: Vue.prototype.$settings.keycloakRealm,
//             clientId: Vue.prototype.$settings.keycloakClientId,
//             onLoad: "login-required"
//           };

//           // Vue.prototype.process = { env: process.env };

//           let keycloak = Keycloak(initOptions);

//           keycloak
//             .init({
//               onLoad: initOptions.onLoad
//             })
//             .then(async authenticated => {
//               if (!authenticated) {
//                 window.location.reload();
//               } else {
//                 Vue.prototype.$keycloak = keycloak;
//                 store.dispatch("app/setToken", keycloak);
//                 await getUserInfo().then(async response => {
//                   typeof loading !== "undefined" ? loading.close() : console.log("dev关闭loading插件");
//                   if (response.resultStatus === "Success" && response.statusCode === 200) {
//                     let data = response.result;
//                     console.log("userData", data);
//                     data.currency = Vue.prototype.$settings.currency || "JPY";
//                     sessionStorage.setItem("currency", Vue.prototype.$settings.currency || "JPY");
//                     store.dispatch("app/setAppInfo", data);

//                     //集成Google Analytics
//                     // if (process.env.VUE_APP_GA_ID) {
//                     //   Vue.use(
//                     //     VueGtag,
//                     //     {
//                     //       config: { id: process.env.VUE_APP_GA_ID }
//                     //     },
//                     //     router
//                     //   );
//                     // }

//                     //新增mfa检查逻辑
//                     //!Vue.prototype.$settings.enableMfa || (Vue.prototype.$settings.enableMfa && data.mfa)
//                     if (!(!Vue.prototype.$settings.enableMfa || (Vue.prototype.$settings.enableMfa && data.mfa))) {
//                       console.log("未绑定MFA强制登出");
//                       window.location.replace("./#/panel");
//                       // this.$router.replace("/panel");

//                       // this.$store.dispatch("app/logout").then(() => {
//                       // this.$keycloak.clearToken();
//                       // this.$keycloak.logout();
//                       // let a = this.$keycloak.createLogoutUrl();
//                       // console.log(a);
//                       // });
//                       // this.$keycloak.clearToken();
//                       function createHiddenIframe(url) {
//                         // 创建一个新的iframe元素
//                         var iframe = document.createElement("iframe");

//                         // 设置iframe的src属性为给定的URL
//                         iframe.src = url;
//                         // console.log(src);

//                         // 设置其他必要的属性使iframe隐藏
//                         iframe.style.display = "none";
//                         iframe.width = "0";
//                         iframe.height = "0";
//                         iframe.border = "0";

//                         // 将iframe添加到文档的body中
//                         document.body.appendChild(iframe);
//                       }
//                       var url = keycloak.createLogoutUrl(); //
//                       // createHiddenIframe(url);
//                     } else {
//                       console.log("已绑定MFA");
//                     }
//                     //

//                     //单独设定，根据SAASConfigAPI设定动态路由
//                     console.log("newStoreVersion?", Vue.prototype.$settings.newStoreVersion);

//                     //单独设定，根据 OEM 显示 OEM favicon
//                     var faviconLink = document.querySelector('link[rel="icon"]');
//                     faviconLink.href = `${Vue.prototype.$settings.style}_favi.ico`;
//                     console.log("router:", router);

//                     //初始化界面
//                     let vm = new Vue({
//                       router,
//                       store,
//                       i18n,
//                       render: h => h(App)
//                     }).$mount("#app");

//                     vm.$router.addRoute("Setting", {
//                       path: "shop",
//                       component: () =>
//                         Vue.prototype.$settings.newStoreVersion === true
//                           ? import("@/pages/Setting/Store.vue")
//                           : import("@/pages/Setting/Shop.vue"),
//                       name: "Shop",
//                       meta: {
//                         title: i18n.tc("storeManagement"),
//                         permission: "Shop"
//                       }
//                     });
//                     vm.$forceUpdate();
//                     console.log("vm", vm);
//                   } else {
//                     store.dispatch("app/logout").then(() => {
//                       keycloak.logout();
//                     });
//                   }
//                 });
//               }
//             })
//             .catch(() => {
//               store.dispatch("app/logout").then(() => {
//                 keycloak.logout();
//               });
//             });

//           keycloak.onTokenExpired = () => {
//             keycloak
//               .updateToken(5)
//               .then(refreshed => {
//                 if (refreshed) {
//                   store.dispatch("app/setToken", keycloak);
//                 }
//               })
//               .catch(() => {
//                 store.dispatch("app/logout").then(() => {
//                   keycloak.logout();
//                 });
//               });
//           };

//           //载入chatbot
//           Vue.prototype.$window = window;
//           console.log(Vue.prototype.$settings.chatBot);
//           if (Vue.prototype.$settings.chatBot) {
//             window.localStorage.setItem("cb-baseUrl", Vue.prototype.$settings.chatBotApi);
//             cbinit({
//               silent: true
//             });
//           }
//         } else {
//           //如果没获取到配置，就跳转到维护界面
//           console.error("获取OEM配置失败，请联系管理员");
//           window.location.replace("/m.html");
//         }
//       } else {
//         //防止接口崩坏
//         console.error("接口访问失败，请检查网络");
//         console.error(xhr.status, xhr.statusText);
//         //接口挂了直接进入免战牌
//         window.location.replace("/m.html");
//       }

//       // if (Vue.prototype.$settings) {
//       // 	//设置标题
//       // 	document.title = document.title + " - " + Vue.prototype.$settings.header.title + " -";
//       // 	//动态加载comp
//       // 	for (item in Vue.prototype.$settings.components) {
//       // 		console.log(document.getElementsByTagName("body")[0].id, item);
//       // 		if (document.getElementsByTagName("body")[0].id == item) {
//       // 			loadJS(Vue.prototype.$settings.components[item], function () {
//       // 				Vue.prototype.$pageCp = new cp();
//       // 				Vue.prototype.$pageCp.$mount("#container");
//       // 			});
//       // 		}
//       // 	}
//       // 	//初始化i18n
//       // }

//       //统计脚本加载
//       // if (Vue.prototype.$settings.analytics && Vue.prototype.$settings.analytics.length > 0) {
//       // 	console.log("analytics");
//       // 	Vue.prototype.$settings.analytics.forEach((e) => {
//       // 		if (e.type === "google") {
//       // 			var script = document.createElement("script");
//       // 			script.type = "text/javascript";
//       // 			script.src = e.url;
//       // 			script.async = true;

//       // 			var script2 = document.createElement("script");
//       // 			script2.type = "text/javascript";
//       // 			try {
//       // 				script2.appendChild(
//       // 					document.createTextNode(
//       // 						`window.dataLayer = window.dataLayer || [];
//       // 							function gtag(){dataLayer.push(arguments);}
//       // 							gtag('js', new Date());
//       // 							gtag('config', '${e.id}');`
//       // 					)
//       // 				);
//       // 			} catch (ex) {
//       // 				console.eror(ex);
//       // 				script2.text = `window.dataLayer = window.dataLayer || [];
//       // 							function gtag(){dataLayer.push(arguments);}
//       // 							gtag('js', new Date());
//       // 							gtag('config', '${e.id}');`;
//       // 			}

//       // 			var head = document.getElementsByTagName("head")[0];

//       // 			head.appendChild(script);
//       // 			head.appendChild(script2);
//       // 		}
//       // 	});
//       // }
//     }
//   };
//   xhr.send(null);
// }
function getOEMConfig() {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", baseUrl + "/api/tenant/getcurrenttenantwebconfig", true);

    xhr.onerror = function(e) {
      console.error(xhr.statusText);
      reject(new Error("Network error"));
    };

    xhr.setRequestHeader("api-version", "2.0");

    xhr.onload = function(e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            console.log("getOEMConfigSuccess");

            Vue.prototype.$settings = response.result;

            // 增加判断逻辑，如果系统维护，就跳转到维护界面
            if (!window.location.search.includes("systemconfig")) {
              if (Vue.prototype.$settings.showServiceUnavailable) {
                window.location.replace("/m.html");
                return;
              }
            }

            // 如果envmode = development 给我注释掉loading
            console.log("ENV:", process.env.NODE_ENV);
            let loading;
            if (!(process.env.NODE_ENV === "development")) {
              loading = Loading.service({
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                fullscreen: true
              });
            }

            // 动态初始化keycloak
            const initOptions = {
              url: Vue.prototype.$settings.keycloakUrl,
              realm: Vue.prototype.$settings.keycloakRealm,
              clientId: Vue.prototype.$settings.keycloakClientId,
              onLoad: "login-required"
            };

            const keycloak = Keycloak(initOptions);

            keycloak
              .init({
                onLoad: initOptions.onLoad
              })
              .then(authenticated => {
                if (!authenticated) {
                  window.location.reload();
                } else {
                  Vue.prototype.$keycloak = keycloak;
                  store.dispatch("app/setToken", keycloak);
                  getUserInfo().then(response => {
                    if (typeof loading !== "undefined") loading.close();
                    if (response.resultStatus === "Success" && response.statusCode === 200) {
                      const data = response.result;
                      console.log("userData", data);
                      data.currency = Vue.prototype.$settings.currency || "JPY";
                      sessionStorage.setItem("currency", Vue.prototype.$settings.currency || "JPY");
                      store.dispatch("app/setAppInfo", data);

                      // 新增mfa检查逻辑
                      if (!(!Vue.prototype.$settings.enableMfa || (Vue.prototype.$settings.enableMfa && data.mfa))) {
                        console.log("未绑定MFA强制登出");
                        window.location.replace("./#/panel");
                      } else {
                        console.log("已绑定MFA");
                      }

                      console.log("newStoreVersion?", Vue.prototype.$settings.newStoreVersion);

                      // 单独设定，根据 OEM 显示 OEM favicon
                      const faviconLink = document.querySelector('link[rel="icon"]');
                      faviconLink.href = `${Vue.prototype.$settings.style}_favi.ico`;

                      // 初始化界面
                      resolve();
                    } else {
                      store.dispatch("app/logout").then(() => {
                        keycloak.logout();
                      });
                    }
                  });
                }
              })
              .catch(() => {
                store.dispatch("app/logout").then(() => {
                  keycloak.logout();
                });
              });

            keycloak.onTokenExpired = () => {
              keycloak
                .updateToken(5)
                .then(refreshed => {
                  if (refreshed) {
                    store.dispatch("app/setToken", keycloak);
                  }
                })
                .catch(() => {
                  store.dispatch("app/logout").then(() => {
                    keycloak.logout();
                  });
                });
            };

            // 载入chatbot
            Vue.prototype.$window = window;
            if (Vue.prototype.$settings.chatBot) {
              window.localStorage.setItem("cb-baseUrl", Vue.prototype.$settings.chatBotApi);
              cbinit({
                silent: true
              });
            }
          } else {
            console.error("获取OEM配置失败，请联系管理员");
            window.location.replace("/m.html");
            reject(new Error("Failed to get OEM configuration"));
          }
        } else {
          console.error("接口访问失败，请检查网络");
          console.error(xhr.status, xhr.statusText);
          window.location.replace("/m.html");
          reject(new Error(`Request failed with status ${xhr.status}`));
        }
      }
    };
    xhr.send(null);
  });
}
// getOEMConfig();
(async function bootstrap() {
  try {
    // 等待设置获取完成
    await getOEMConfig();

    // 动态添加路由
    router.addRoute("Layout", {
      path: "panel",
      component: () => import("@/pages/Panel/index.vue"),
      name: "Panel",
      meta: {
        title: i18n.tc("dashboard"),
        permission: Vue.prototype.$settings.showDashBoard ? "Record" : "Panel",
        $settings: Vue.prototype.$settings.showDashBoard
      }
    });

    router.addRoute("Setting", {
      path: "shop",
      component: () =>
        Vue.prototype.$settings.newStoreVersion === true
          ? import("@/pages/Setting/Store.vue")
          : import("@/pages/Setting/Shop.vue"),
      name: "Shop",
      meta: {
        title: i18n.tc("storeManagement"),
        permission: "Shop"
      }
    });

    console.log("router:", router);
    // 初始化 Vue 实例
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount("#app");
  } catch (error) {
    console.error("Initialization failed:", error);
  }
})();
